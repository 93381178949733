<template>
  <div id="search-airport">
    <v-select
      ref="vSelectRef"
      :value="valueProp"
      append-to-body
      :calculate-position="withPopper"
      :filterable="false"
      :clearable="false"
      :get-option-label="(searchData) => searchData"
      :options="airportOptions"
      :placeholder="placeholder"
      :get-option-key="getOptionKey"
      @open="handleOpenAirport"
      @search="handleSearchAirport"
      @option:selected="handleChooseAirport"
    >
      <template #no-options="{ search, searching }">
        <template v-if="isLoadingSearchAirport">
          <b-spinner
            variant="primary"
            label="Text Centered"
            small
            tag="span"
          />
          <span class="pl-1"> {{ $t('flight.loadingAirport') }} </span>
        </template>
        <template v-else-if="searching">
          {{ $t('flight.noResultFound') }} <b>{{ search }}</b>
        </template>
        <template v-else>
          <div v-if="!((showDefaultAirport || !search))">
            {{ $t('flight.noOptions') }}
          </div>
          <div
            v-else
            class="d-none"
          />
        </template>
      </template>

      <template
        slot="selected-option"
        slot-scope="data"
      >
        <div class="text-truncate">
          <div class="d-flex justify-content-start text-title">
            <label
              class="text-dark mb-0"
              :class="isMultiCity ? ' font-medium-3' : ' font-medium-4'"
            >
              {{ data.city || data.name }}
            </label>
            <span class="text-warning ml-50">{{ data.iata }}</span>
          </div>
          <div class="d-flex justify-content-start align-items-center">
            <div class="d-flex justify-content-between">
              <label
                class="text-black-50 mb-0"
                style="font-weight: 400"
              >
                {{ data.city ? `${data.name},` : '' }} {{ data.country || data.group }}
              </label>
            </div>
          </div>
        </div>
      </template>

      <template #list-header="{ search, searching }">
        <b-card
          v-if="(showDefaultAirport || !search) && !searching"
          no-body
          class="h-25"
        >
          <b-tabs
            vertical
            pills
            nav-wrapper-class="nav-vertical p-0"
            content-class="p-0"
          >
            <b-tab
              v-for="(group, index) in airportOptionsDefault.groups"
              :key="index"
            >
              <template #title>
                <span class="text-uppercase text-left">
                  {{ group.displayName }}
                </span>
              </template>
              <vue-perfect-scrollbar
                style="max-height: 28em"
                :settings="{
                  maxScrollbarLength: 60,
                  wheelPropagation: false,
                }"
                class="ps-customizer-area scroll-area"
              >
                <div
                  v-for="(item, i) in group.airports"
                  :key="i"
                >
                  <b-button
                    variant="flat-primary"
                    class="w-100 text-left text-body"
                    @click="handleChooseAirport(item, true)"
                  >
                    {{ item.name }} (<span class="font-weight-bolder text-dark">{{ item.iata }}</span>)
                  </b-button>
                </div>
              </vue-perfect-scrollbar>
            </b-tab>
          </b-tabs>
        </b-card>
      </template>

      <template #option="data">
        <div v-if="!showDefaultAirport">
          <div class="d-flex justify-content-start text-truncate">
            <strong>{{ data.city }}</strong>
            <strong :class="`${valueProp && (data.iata !== valueProp.iata) || !valueProp ? 'text-warning' : ''} ml-1 text-truncate font-weight-bold`">
              {{ data.iata }}
            </strong>
          </div>
          <div class="d-flex justify-content-between text-truncate">
            <span class="text-truncate">
              {{ data.name }}, {{ data.country }}
            </span>
          </div>
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import {
  BSpinner, BButton, BTabs, BTab, BCard,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  toRefs,
  watchEffect,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import { isEmpty, debounce } from 'lodash'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { createPopper } from '@popperjs/core'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import { removeAccents } from '@core/comp-functions/forms/formatter-input'

import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BSpinner,
    BButton,
    BTabs,
    BTab,
    BCard,
    vSelect,
    VuePerfectScrollbar,
  },
  props: {
    dataProp: {
      type: [Object, String],
      default: () => {},
    },
    typeProp: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    right: {
      type: Boolean,
      default: false,
    },
    isMultiCity: {
      type: Boolean,
      default: false,
    },
    isSearchClassBooking: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      fetchAirports, getAirportGroup: airportOptionsDefault, getAirportByAirportCode, getAirportsByCodeList,
    } = useBookingHandle()
    const airportProp = toRefs(props).dataProp
    const fetchingAirport = ref(false)
    const airportData = ref(null)

    watchEffect(async () => {
      airportData.value = null
      if (typeof airportProp.value === 'string' && !isEmpty(airportProp.value)) {
        const temp = getAirportByAirportCode(airportProp.value)
        if (temp) {
          airportData.value = temp
        } else if (!fetchingAirport.value) {
          fetchingAirport.value = true
          await getAirportsByCodeList([airportProp.value])
            .then(response => {
              airportData.value = response.data.find(point => point.iata === airportProp.value) || null
            })
            .finally(() => {
              fetchingAirport.value = false
            })
        }
      } else {
        airportData.value = cloneDeep(airportProp.value)
      }
    })

    const valueProp = computed(() => airportData.value)

    // const typeFlight = toRefs(props).typeProp
    // const isSearchClassBooking = toRefs(props).isSearchClassBooking
    const isLoadingSearchAirport = ref(false)
    const airportOptions = ref([])
    // const { toastError } = useToast()
    const vSelectRef = ref()

    function fetchAirportHandle(searchTextAirport = null, callback = () => { }) {
      airportOptions.value = []
      isLoadingSearchAirport.value = true

      fetchAirports(searchTextAirport)
        .then(response => {
          if (response) {
            airportOptions.value = response.data.items
            // if (!searchTextAirport) airportOptionsDefault.value = response.data.items
            callback(airportOptions.value[0])
          }
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          isLoadingSearchAirport.value = false
        })
    }

    const handleOpenAirport = () => {
      airportOptions.value = []
    }

    const handleSearchAirport = debounce(searchTextAirport => {
      if (searchTextAirport) fetchAirportHandle(removeAccents(searchTextAirport))
    }, 500)

    const showDefaultAirport = computed(() => isEmpty(airportOptions.value))

    const handleChooseAirport = (item, closeDropdown = false) => {
      // if (!isSearchClassBooking.value && typeFlight.value === 'MC' && !(item.group === 'VIỆT NAM' || item.country === 'VN')) {
      //   toastError({
      //     title: 'messagesList.error',
      //     content: 'messagesList.flight.multiCityMustBeDomestic',
      //   })
      //   return
      // }
      emit('update:data-prop', item)
      if (closeDropdown) vSelectRef.value.searchEl.blur() // -> close dropdown programmatically
    }

    const getOptionKey = val => `${val.id}-${uuidv4()}`

    return {
      airportOptions,
      airportOptionsDefault,
      handleOpenAirport,
      handleSearchAirport,
      showDefaultAirport,
      isLoadingSearchAirport,
      handleChooseAirport,
      valueProp,
      vSelectRef,
      getOptionKey,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownWidth = `calc(${Number(width.split('px')[0]) + 50}px )` || width // + 25px
      dropdownList.style.width = dropdownWidth
      dropdownList.style.zIndex = '10000'
      // dropdownList.style['overflow-x'] = 'hidden'
      dropdownList.style.translate = `calc((${dropdownWidth} - ${this.right ? '0' : width})/2${this.right ? '*(-1)' : ''})`
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          // {
          //   name: 'offset',
          //   options: {
          //     offset: [0, -1],
          //   },
          // },
          // {
          //   name: 'toggleClass',
          //   enabled: true,
          //   phase: 'write',
          //   fn({ state }) {
          //     component.$el.classList.toggle(
          //       'drop-up',
          //       state.placement === 'top',
          //     )
          //   },
          // },
        ],
      })

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

#search-airport ::v-deep {
  .vs__dropdown-toggle {
    border: none;
    height: 60px;
    padding: 0px !important;
  }
  .vs__dropdown-menu {
    min-width: 360px !important;
  }
  .vs__selected-options {
    min-width: 0;
    flex-wrap: nowrap;
    .vs__selected {
      min-width: 0;
    }
    .vs__search {
      width: 0px !important;
      padding: 0px !important;
    }
  }
}

.ps-customizer-area {
  height: calc(100% - 83px);
}
</style>
